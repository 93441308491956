<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="historyPoints.data"
                :fields="historyFields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
              >
                <template v-slot:cell(note)="row">
                  <span v-if="row.item.payload">
                    {{ JSON.parse(row?.item?.payload)?.notes }}
                  </span>
                  <span v-else>---</span>
                </template>
              </b-table>
            </div>
            <template v-if="historyPoints.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="historyPoints.total"
                      :total-pages="historyPoints.total_pages"
                      :per-page="historyPoints.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
            <div
              class="no_content my-4"
              v-if="historyPoints?.data?.length == 0"
            >
              <div style="display: grid; justify-items: center">
                <img src="../../../../public/noData.svg" alt="" />
                <span class="no_content_text">No Data Found</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import PointsMixin from "../../../mixins/ModuleJs/points";

export default {
  mixins: [MixinRequest, PointsMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "View Points",
      items: [
        {
          text: "Back",
          href: "/points",
        },
        {
          text: "Data",
        },
      ],
    };
  },
};
</script>
